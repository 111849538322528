import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { localApi } from "utils/imgApi";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import styleDailog from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

import { credentials } from "utils/imgApi";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useStyleDailog = makeStyles(styleDailog);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function Enquiry() {
  const [dataOrders, setDataOrder] = React.useState([]);
  const [noticeModal, setNoticeModal] = React.useState(false);

  const [enquiryID, setEnquiryId] = React.useState("");
  const [enquiry, setEnquiry] = React.useState("");

  const classes = useStyles();
  const classeDailog = useStyleDailog();
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getSets() {
    const apiReg = localApi + `/register/allList`;
    const resultReg = await fetch(apiReg, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResultRegister = await resultReg.json();

    const getResultReg = getResultRegister.ClientList;
    // console.log(getResultReg);
    
    const api = localApi + `/enquiry/allList`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResults = await result.json();
    // const getResult = getResults.EnquiryList.filter(
    //   (f) => f.adminId === userObj.id
    // );
    const getResult = getResults.EnquiryList;
    // console.log(getResult);

    getResult.forEach((enquiry) => {
      const matchedReg = getResultReg.find((f) => f.id === enquiry.clientId);
      if (matchedReg) {
        enquiry.loDate = matchedReg.loDate;
        enquiry.lastDays = matchedReg.lastDays;
      }
    });

    // console.log(getResult);

    setDataOrder(
      getResult &&
        getResult.map((or, key) => {
          return {
            sino: key + 1,
            productId: or.productId,
            company: or.company,
            mobile: or.mobile,
            email: or.email,
            loginDate: or.loDate + " (" + or.lastDays + ")",
            actions: (
              <div className="actions-right">
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    setNoticeModal(true);
                    setEnquiryId(or.id);
                    setEnquiry(or.message);
                  }}
                  color="danger"
                  className="remove"
                >
                  <VisibilityIcon />
                </Button>{" "}
              </div>
            ),
          };
        })
    );
  }

  useEffect(() => {
    getSets();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Enquiry List</h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={[
                {
                  Header: "SI.No.",
                  accessor: "sino",
                },
                {
                  Header: "ProductId",
                  accessor: "productId",
                },
                {
                  Header: "Company",
                  accessor: "company",
                },
                {
                  Header: "Mobile",
                  accessor: "mobile",
                },
                {
                  Header: "Email",
                  accessor: "email",
                },
                {
                  Header: "Login Date",
                  accessor: "loginDate",
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                },
              ]}
              data={dataOrders}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} className={classeDailog.center}>
          <Dialog
            classes={{
              root: classeDailog.center + " " + classeDailog.modalRoot,
              paper: classeDailog.modal,
            }}
            open={noticeModal}
            TransitionComponent={Transition}
            keepMounted
            // onClose={() => setNoticeModal(false)}
            aria-labelledby="notice-modal-slide-title"
            aria-describedby="notice-modal-slide-description"
          >
            <DialogTitle
              id="notice-modal-slide-title"
              disableTypography
              className={classeDailog.modalHeader}
            >
              <Button
                justIcon
                className={classeDailog.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => setNoticeModal(false)}
              >
                <Close className={classeDailog.modalClose} />
              </Button>
              <h4
                className={classeDailog.modalTitle}
                style={{ fontWeight: "bold" }}
              >
                EnquiryId: {enquiryID}
              </h4>
            </DialogTitle>
            <DialogContent
              id="notice-modal-slide-description"
              className={classeDailog.modalBody}
            >
              <GridContainer>
                <GridItem xs={3} sm={3} md={12}>
                  <span className={classeDailog.paymentOrder}>{enquiry}</span>
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions
              className={
                classeDailog.modalFooter + " " + classeDailog.modalFooterCenter
              }
            >
              {/* <Button color="rose" round onClick={() => handleOrderUpdate()}>
                  update
                </Button> */}
            </DialogActions>
          </Dialog>
        </GridItem>
      </GridContainer>
    </GridContainer>
  );
}
