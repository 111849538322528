import Dashboard from "views/Dashboard/Dashboard.js";
import Enquiry from "./views/Pages/Enquiry.js";
import ErrorPage from "views/Pages/ErrorPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import LabourPage from "views/Pages/LabourPage.js";
import PricingPage from "views/Pages/PricingPage.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import EditProfile from "views/Pages/EditProfile";
import LogoAndSign from "views/Pages/LogoAndSign.js";

import ImagesAdd from "views/Pages/ImagesAdd.js";
import ImagesUpdate from "views/Pages/ImagesUpdate.js";
import ImagesAddCrop from "views/Pages/ImagesAddCrop.js";
import ImagesUpdateCrop from "views/Pages/ImagesUpdateCrop.js";

import AddCategory from "views/Pages/AddCategory.js";
import UpdateCategory from "views/Pages/UpdateCategory.js";
import DeleteCategory from "views/Pages/DeleteCategory.js";

import AddCatSize from "views/Pages/AddCategorySize.js";
import UpdateCatSize from "views/Pages/UpdateCategorySize.js";
import DeleteCatSize from "views/Pages/DeleteCategorySize.js";

import AddLabourRate from "views/Pages/AddLabourRate.js";
import UpdateLabourRate from "views/Pages/UpdateLabourRate.js";
import UpdateLabRateSingle from "views/Pages/UpdateLabRateSingle.js";
import DeleteLabourRate from "views/Pages/DeleteLabourRate.js";
import CategoryIcon from "@material-ui/icons/Category";

import AddSets from "./views/Pages/AddSet.js";
import UpdateSet from "./views/Pages/UpdateSet.js";
import DeleteSet from "./views/Pages/DeleteSet.js";

import { FaBox, FaRing } from "react-icons/fa";
import { GiWireCoil, GiBoxUnpacking, GiShoppingCart } from "react-icons/gi";

import AddPacking from "views/Pages/AddPacking.js";
import UpdatePacking from "views/Pages/UpdatePacking.js";
import DeletePacking from "views/Pages/DeletePacking.js";

import AddMetal from "views/Pages/AddMetal.js";
import UpdateMetal from "views/Pages/UpdateMetal.js";
import DeleteMetal from "views/Pages/DeleteMetal.js";

import AddUnit from "views/Pages/AddUnit.js";
import UpdateUnit from "views/Pages/UpdateUnit.js";
import DeleteUnit from "views/Pages/DeleteUnit.js";
import AcUnitIcon from "@material-ui/icons/AcUnit";

import AddBox from "views/Pages/AddBox.js";
import UpdateBox from "views/Pages/UpdateBox.js";
import DeleteBox from "views/Pages/DeleteBox.js";

import AddClient from "views/Pages/AddClient.js";
import UpdateClient from "views/Pages/UpdateClientProfile.js";
import UpdateShippingAddress from "views/Pages/UpdateShippingAddress.js";
import ViewClientDetails from "views/Pages/ViewClientDetails.js";
import LastUpdatedClient from "views/Pages/LastUpdatedClient.js";
import { HiOutlineUserAdd } from "react-icons/hi";
import AddAddress from "views/Pages/AddAddress.js";
import ViewClientAddress from "views/Pages/ViewClientAddress.js";
import UserList from "views/Pages/UserList.js";
import Message from "views/Pages/Message.js";

import AddTransport from "views/Pages/AddTransport.js";
import AddBranch from "views/Pages/AddBranchTransport.js";
import UpdateBranch from "views/Pages/UpdateBranch.js";
import ViewBranch from "views/Pages/ViewBranch.js";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

import AddProduct from "views/Pages/AddProduct.js";
import { RiProductHuntLine } from "react-icons/ri";
import UpdateProduct from "views/Pages/UpdateProduct.js";
import DeleteProduct from "views/Pages/DeleteProduct.js";
import ViewProduct from "views/Pages/ViewProduct";
import OnlineProductUpdate from "views/Pages/OnlineProductUpdate";
import ProductContent from "views/Pages/ProductContent.js";

import AddStockProduct from "views/Pages/AddStockProduct.js";
import UpdateStockProduct from "views/Pages/UpdateStockProduct.js";
import DeleteStockProduct from "views/Pages/DeleteStockProduct.js";
import ViewStockProduct from "views/Pages/ViewStockProduct.js";

import AddCart from "views/Pages/AddCart.js";
import UpdateCart from "views/Pages/UpdateCart.js";
import UpdatePendingCart from "views/Pages/UpdatePendingCart.js";
import DeleteCart from "views/Pages/DeleteCart.js";
import ViewCart from "views/Pages/ViewCart.js";
import MyOrder from "views/Pages/MyOrder.js";
import OrderHistory from "views/Pages/OrderHistory.js";
import OrderList from "views/Pages/OrderList.js";
import OrderPendingAmount from "views/Pages/OrderPandingAmount.js";
import OrdersCancel from "views/Pages/OrdersCancel.js";
import CartPending from "views/Pages/CartPending.js";
import ViewPendingCart from "views/Pages/ViewPendingCart.js";
import Follower from "views/Pages/Follower.js";

import AddTerms from "views/Pages/AddTerms.js";
import UpdateTerms from "views/Pages/UpdateTerms.js";
import ViewTerms from "views/Pages/ViewTerms.js";

import Address from "views/Pages/Address.js";
import ViewAddress from "views/Pages/ViewAddress.js";
import ReceiptIcon from "@material-ui/icons/Receipt";

import LabourAddMetal from "views/Pages/LabourAddMetal.js";
import LabourEditMetal from "views/Pages/LabourEditMetal.js";
import LabourDeleteMetal from "views/Pages/LabourDeleteMetal.js";
import LabourAllViewMetal from "views/Pages/LabourAllViewMetal.js";
import LabourClientViewMetal from "views/Pages/LabourClientViewMetal.js";
import LabourReceiveAddMetal from "views/Pages/LabourReceiveAddMetal.js";
import LabourReceiveEditMetal from "views/Pages/LabourReceiveEditMetal.js";
import LabourReceiveDeleteMetal from "views/Pages/LabourReceiveDeleteMetal.js";
import LabourReceiveAllViewMetal from "views/Pages/LabourReceiveAllViewMetal.js";
import LabourReceiveClientViewMetal from "views/Pages/LabourReceiveClientViewMetal.js";

import LabourAddRolling from "views/Pages/LabourAddRolling.js";
import LabourEditRolling from "views/Pages/LabourEditRolling.js";
import LabourDeleteRolling from "views/Pages/LabourDeleteRolling.js";
import LabourAllViewRolling from "views/Pages/LabourAllViewRolling.js";
import LabourClientViewRolling from "views/Pages/LabourClientViewRolling.js";
import LabourReceiveAddRolling from "views/Pages/LabourReceiveAddRolling.js";
import LabourReceiveEditRolling from "views/Pages/LabourReceiveEditRolling.js";
import LabourReceiveDeleteRolling from "views/Pages/LabourReceiveDeleteRolling.js";
import LabourReceiveAllViewRolling from "views/Pages/LabourReceiveAllViewRolling.js";
import LabourReceiveClientViewRolling from "views/Pages/LabourReceiveClientViewRolling.js";

import LabourAddDie from "views/Pages/LabourAddDie.js";
import LabourEditDie from "views/Pages/LabourEditDie.js";
import LabourDeleteDie from "views/Pages/LabourDeleteDie.js";
import LabourAllViewDie from "views/Pages/LabourAllViewDie.js";
import LabourClientViewDie from "views/Pages/LabourClientViewDie.js";
import LabourReceiveAddDie from "views/Pages/LabourReceiveAddDie.js";
import LabourReceiveEditDie from "views/Pages/LabourReceiveEditDie.js";
import LabourReceiveDeleteDie from "views/Pages/LabourReceiveDeleteDie.js";
import LabourReceiveAllViewDie from "views/Pages/LabourReceiveAllViewDie.js";
import LabourReceiveClientViewDie from "views/Pages/LabourReceiveClientViewDie.js";

import LabourAddSolder from "views/Pages/LabourAddSolder.js";
import LabourEditSolder from "views/Pages/LabourEditSolder.js";
import LabourDeleteSolder from "views/Pages/LabourDeleteSolder.js";
import LabourAllViewSolder from "views/Pages/LabourAllViewSolder.js";
import LabourClientViewSolder from "views/Pages/LabourClientViewSolder.js";
import LabourReceiveAddSolder from "views/Pages/LabourReceiveAddSolder.js";
import LabourReceiveEditSolder from "views/Pages/LabourReceiveEditSolder.js";
import LabourReceiveDeleteSolder from "views/Pages/LabourReceiveDeleteSolder.js";
import LabourReceiveAllViewSolder from "views/Pages/LabourReceiveAllViewSolder.js";
import LabourReceiveClientViewSolder from "views/Pages/LabourReceiveClientViewSolder.js";

import LabourAddAcid from "views/Pages/LabourAddAcid.js";
import LabourEditAcid from "views/Pages/LabourEditAcid.js";
import LabourDeleteAcid from "views/Pages/LabourDeleteAcid.js";
import LabourAllViewAcid from "views/Pages/LabourAllViewAcid.js";
import LabourClientViewAcid from "views/Pages/LabourClientViewAcid.js";
import LabourReceiveAddAcid from "views/Pages/LabourReceiveAddAcid.js";
import LabourReceiveEditAcid from "views/Pages/LabourReceiveEditAcid.js";
import LabourReceiveDeleteAcid from "views/Pages/LabourReceiveDeleteAcid.js";
import LabourReceiveAllViewAcid from "views/Pages/LabourReceiveAllViewAcid.js";
import LabourReceiveClientViewAcid from "views/Pages/LabourReceiveClientViewAcid.js";

import LabourAddDell from "views/Pages/LabourAddDell.js";
import LabourEditDell from "views/Pages/LabourEditDell.js";
import LabourDeleteDell from "views/Pages/LabourDeleteDell.js";
import LabourAllViewDell from "views/Pages/LabourAllViewDell.js";
import LabourClientViewDell from "views/Pages/LabourClientViewDell.js";
import LabourReceiveAddDell from "views/Pages/LabourReceiveAddDell.js";
import LabourReceiveEditDell from "views/Pages/LabourReceiveEditDell.js";
import LabourReceiveDeleteDell from "views/Pages/LabourReceiveDeleteDell.js";
import LabourReceiveAllViewDell from "views/Pages/LabourReceiveAllViewDell.js";
import LabourReceiveClientViewDell from "views/Pages/LabourReceiveClientViewDell.js";

import LabourAddCutting from "views/Pages/LabourAddCutting.js";
import LabourEditCutting from "views/Pages/LabourEditCutting.js";
import LabourDeleteCutting from "views/Pages/LabourDeleteCutting.js";
import LabourAllViewCutting from "views/Pages/LabourAllViewCutting.js";
import LabourClientViewCutting from "views/Pages/LabourClientViewCutting.js";
import LabourReceiveAddCutting from "views/Pages/LabourReceiveAddCutting.js";
import LabourReceiveEditCutting from "views/Pages/LabourReceiveEditCutting.js";
import LabourReceiveDeleteCutting from "views/Pages/LabourReceiveDeleteCutting.js";
import LabourReceiveAllViewCutting from "views/Pages/LabourReceiveAllViewCutting.js";
import LabourReceiveClientViewCutting from "views/Pages/LabourReceiveClientViewCutting.js";

import LabourAddPlating from "views/Pages/LabourAddPlating.js";
import LabourEditPlating from "views/Pages/LabourEditPlating.js";
import LabourDeletePlating from "views/Pages/LabourDeletePlating.js";
import LabourAllViewPlating from "views/Pages/LabourAllViewPlating.js";
import LabourClientViewPlating from "views/Pages/LabourClientViewPlating.js";
import LabourReceiveAddPlating from "views/Pages/LabourReceiveAddPlating.js";
import LabourReceiveEditPlating from "views/Pages/LabourReceiveEditPlating.js";
import LabourReceiveDeletePlating from "views/Pages/LabourReceiveDeletePlating.js";
import LabourReceiveAllViewPlating from "views/Pages/LabourReceiveAllViewPlating.js";
import LabourReceiveClientViewPlating from "views/Pages/LabourReceiveClientViewPlating.js";

import LabourAddPacking from "views/Pages/LabourAddPacking.js";
import LabourEditPacking from "views/Pages/LabourEditPacking.js";
import LabourDeletePacking from "views/Pages/LabourDeletePacking.js";
import LabourAllViewPacking from "views/Pages/LabourAllViewPacking.js";
import LabourClientViewPacking from "views/Pages/LabourClientViewPacking.js";
import LabourReceiveAddPacking from "views/Pages/LabourReceiveAddPacking.js";
import LabourReceiveEditPacking from "views/Pages/LabourReceiveEditPacking.js";
import LabourReceiveDeletePacking from "views/Pages/LabourReceiveDeletePacking.js";
import LabourReceiveAllViewPacking from "views/Pages/LabourReceiveAllViewPacking.js";
import LabourReceiveClientViewPacking from "views/Pages/LabourReceiveClientViewPacking.js";
import LabourStatus from "views/Pages/LabourStatus.js";

import AddCropImage from "views/Pages/AddCropImage.js";
import AddImage from "views/Pages/AddImage.js";
import ViewImage from "views/Pages/ViewImage.js";
import UpdateCropImage from "views/Pages/UpdateCropImage.js";
import UpdateImage from "views/Pages/UpdateImage.js";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import Image from "@material-ui/icons/Image";
import PersonIcon from "@material-ui/icons/Person";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },

  {
    collapse: true,
    name: "My Profile",
    rtlName: "صفحات",
    icon: PersonIcon,
    state: "profileCollapse",
    views: [
      {
        path: "/editProfile",
        name: "Edit Profile",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "EP",
        rtlMini: "شع",
        component: EditProfile,
        layout: "/admin",
      },
      {
        path: "/logoAndSign",
        name: "Logo And Sign",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "LS",
        rtlMini: "شع",
        component: LogoAndSign,
        layout: "/admin",
      },
      {
        collapse: true,
        name: "Address",
        rtlName: "صفحات",
        icon: PersonIcon,
        state: "addressCollapse",
        views: [
          {
            path: "/address",
            name: "Add Address",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "AAd",
            rtlMini: "شع",
            component: Address,
            layout: "/admin",
          },
          {
            path: "/viewAddress",
            name: "View Address",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "VA",
            rtlMini: "شع",
            component: ViewAddress,
            layout: "/admin",
          },
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "Labour issue",
    rtlName: "صفحات",
    icon: ReceiptIcon,
    state: "labourCollapse",
    views: [
      {
        collapse: true,
        name: "Metal Issue To Party",
        rtlName: "صفحات",
        icon: PersonIcon,
        state: "metalIssueCollapse",
        views: [
          {
            path: "/labourAddMetal",
            name: "Add Metal",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "AM",
            rtlMini: "شع",
            component: LabourAddMetal,
            layout: "/admin",
          },
          {
            path: "/labourEditMetal",
            name: "Edit Metal",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "EM",
            rtlMini: "شع",
            component: LabourEditMetal,
            layout: "/admin",
          },
          {
            path: "/labourDeleteMetal",
            name: "Delete Metal",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "DM",
            rtlMini: "شع",
            component: LabourDeleteMetal,
            layout: "/admin",
          },
          {
            path: "/labourAllViewMetal",
            name: "View All Metal",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "VAM",
            rtlMini: "شع",
            component: LabourAllViewMetal,
            layout: "/admin",
          },
          {
            path: "/labourClientViewMetal",
            name: "View Client Metal",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "VCM",
            rtlMini: "شع",
            component: LabourClientViewMetal,
            layout: "/admin",
          },
          {
            collapse: true,
            name: "Receive To Party",
            rtlName: "صفحات",
            icon: PersonIcon,
            state: "metalRecievedCollapse",
            views: [
              {
                path: "/labourReceiveAddMetal",
                name: "Add Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "AR",
                rtlMini: "شع",
                component: LabourReceiveAddMetal,
                layout: "/admin",
              },
              {
                path: "/labourReceiveEditMetal",
                name: "Edit Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "ER",
                rtlMini: "شع",
                component: LabourReceiveEditMetal,
                layout: "/admin",
              },
              {
                path: "/labourReceiveDeleteMetal",
                name: "Delete Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "DR",
                rtlMini: "شع",
                component: LabourReceiveDeleteMetal,
                layout: "/admin",
              },
              {
                path: "/labourReceiveAllViewMetal",
                name: "View All Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "VAR",
                rtlMini: "شع",
                component: LabourReceiveAllViewMetal,
                layout: "/admin",
              },
              {
                path: "/labourReceiveClientViewMetal",
                name: "View Client Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "VCR",
                rtlMini: "شع",
                component: LabourReceiveClientViewMetal,
                layout: "/admin",
              },
            ],
          },
        ],
      },
      {
        collapse: true,
        name: "Rolling Issue To Party",
        rtlName: "صفحات",
        icon: PersonIcon,
        state: "rollingIssueCollapse",
        views: [
          {
            path: "/labourAddRolling",
            name: "Add Rolling",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "AR",
            rtlMini: "شع",
            component: LabourAddRolling,
            layout: "/admin",
          },
          {
            path: "/labourEditRolling",
            name: "Edit Rolling",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "ER",
            rtlMini: "شع",
            component: LabourEditRolling,
            layout: "/admin",
          },
          {
            path: "/labourDeleteRolling",
            name: "Delete Rolling",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "DR",
            rtlMini: "شع",
            component: LabourDeleteRolling,
            layout: "/admin",
          },
          {
            path: "/labourAllViewRolling",
            name: "View All Rolling",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "VAR",
            rtlMini: "شع",
            component: LabourAllViewRolling,
            layout: "/admin",
          },
          {
            path: "/labourClientViewRolling",
            name: "View Client Rolling",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "VCR",
            rtlMini: "شع",
            component: LabourClientViewRolling,
            layout: "/admin",
          },
          {
            collapse: true,
            name: "Receive To Party",
            rtlName: "صفحات",
            icon: PersonIcon,
            state: "rollingRecievedCollapse",
            views: [
              {
                path: "/labourReceiveAddRolling",
                name: "Add Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "AR",
                rtlMini: "شع",
                component: LabourReceiveAddRolling,
                layout: "/admin",
              },
              {
                path: "/labourReceiveEditRolling",
                name: "Edit Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "ER",
                rtlMini: "شع",
                component: LabourReceiveEditRolling,
                layout: "/admin",
              },
              {
                path: "/labourReceiveDeleteRolling",
                name: "Delete Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "DR",
                rtlMini: "شع",
                component: LabourReceiveDeleteRolling,
                layout: "/admin",
              },
              {
                path: "/labourReceiveAllViewRolling",
                name: "View All Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "VAR",
                rtlMini: "شع",
                component: LabourReceiveAllViewRolling,
                layout: "/admin",
              },
              {
                path: "/labourReceiveClientViewRolling",
                name: "View Client Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "VCR",
                rtlMini: "شع",
                component: LabourReceiveClientViewRolling,
                layout: "/admin",
              },
            ],
          },
        ],
      },
      {
        collapse: true,
        name: "Die Issue To Party",
        rtlName: "صفحات",
        icon: PersonIcon,
        state: "dieIssueCollapse",
        views: [
          {
            path: "/labourAddDie",
            name: "Add Die",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "AD",
            rtlMini: "شع",
            component: LabourAddDie,
            layout: "/admin",
          },
          {
            path: "/labourEditDie",
            name: "Edit Die",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "ED",
            rtlMini: "شع",
            component: LabourEditDie,
            layout: "/admin",
          },
          {
            path: "/labourDeleteDie",
            name: "Delete Die",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "DD",
            rtlMini: "شع",
            component: LabourDeleteDie,
            layout: "/admin",
          },
          {
            path: "/labourAllViewDie",
            name: "View All Die",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "VAD",
            rtlMini: "شع",
            component: LabourAllViewDie,
            layout: "/admin",
          },
          {
            path: "/labourClientViewDie",
            name: "View Client Die",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "VCD",
            rtlMini: "شع",
            component: LabourClientViewDie,
            layout: "/admin",
          },
          {
            collapse: true,
            name: "Receive To Party",
            rtlName: "صفحات",
            icon: PersonIcon,
            state: "dieRecievedCollapse",
            views: [
              {
                path: "/labourReceiveAddDie",
                name: "Add Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "AR",
                rtlMini: "شع",
                component: LabourReceiveAddDie,
                layout: "/admin",
              },
              {
                path: "/labourReceiveEditDie",
                name: "Edit Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "ER",
                rtlMini: "شع",
                component: LabourReceiveEditDie,
                layout: "/admin",
              },
              {
                path: "/labourReceiveDeleteDie",
                name: "Delete Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "DR",
                rtlMini: "شع",
                component: LabourReceiveDeleteDie,
                layout: "/admin",
              },
              {
                path: "/labourReceiveAllViewDie",
                name: "View All Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "VAR",
                rtlMini: "شع",
                component: LabourReceiveAllViewDie,
                layout: "/admin",
              },
              {
                path: "/labourReceiveClientViewDie",
                name: "View Client Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "VCR",
                rtlMini: "شع",
                component: LabourReceiveClientViewDie,
                layout: "/admin",
              },
            ],
          },
        ],
      },
      {
        collapse: true,
        name: "Solder Issue To Party",
        rtlName: "صفحات",
        icon: PersonIcon,
        state: "solderIssueCollapse",
        views: [
          {
            path: "/labourAddSolder",
            name: "Add Solder",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "AS",
            rtlMini: "شع",
            component: LabourAddSolder,
            layout: "/admin",
          },
          {
            path: "/labourEditSolder",
            name: "Edit Solder",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "ES",
            rtlMini: "شع",
            component: LabourEditSolder,
            layout: "/admin",
          },
          {
            path: "/labourDeleteSolder",
            name: "Delete Solder",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "DS",
            rtlMini: "شع",
            component: LabourDeleteSolder,
            layout: "/admin",
          },
          {
            path: "/labourAllViewSolder",
            name: "View All Solder",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "VAS",
            rtlMini: "شع",
            component: LabourAllViewSolder,
            layout: "/admin",
          },
          {
            path: "/labourClientViewSolder",
            name: "View Client Solder",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "VCS",
            rtlMini: "شع",
            component: LabourClientViewSolder,
            layout: "/admin",
          },
          {
            collapse: true,
            name: "Receive To Party",
            rtlName: "صفحات",
            icon: PersonIcon,
            state: "solderRecievedCollapse",
            views: [
              {
                path: "/labourReceiveAddSolder",
                name: "Add Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "AR",
                rtlMini: "شع",
                component: LabourReceiveAddSolder,
                layout: "/admin",
              },
              {
                path: "/labourReceiveEditSolder",
                name: "Edit Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "ER",
                rtlMini: "شع",
                component: LabourReceiveEditSolder,
                layout: "/admin",
              },
              {
                path: "/labourReceiveDeleteSolder",
                name: "Delete Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "DR",
                rtlMini: "شع",
                component: LabourReceiveDeleteSolder,
                layout: "/admin",
              },
              {
                path: "/labourReceiveAllViewSolder",
                name: "View All Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "VAR",
                rtlMini: "شع",
                component: LabourReceiveAllViewSolder,
                layout: "/admin",
              },
              {
                path: "/labourReceiveClientViewSolder",
                name: "View Client Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "VCR",
                rtlMini: "شع",
                component: LabourReceiveClientViewSolder,
                layout: "/admin",
              },
            ],
          },
        ],
      },
      {
        collapse: true,
        name: "Acid Issue To Party",
        rtlName: "صفحات",
        icon: PersonIcon,
        state: "acidIssueCollapse",
        views: [
          {
            path: "/labourAddAcid",
            name: "Add Acid",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "AA",
            rtlMini: "شع",
            component: LabourAddAcid,
            layout: "/admin",
          },
          {
            path: "/labourEditAcid",
            name: "Edit Acid",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "EA",
            rtlMini: "شع",
            component: LabourEditAcid,
            layout: "/admin",
          },
          {
            path: "/labourDeleteAcid",
            name: "Delete Acid",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "DA",
            rtlMini: "شع",
            component: LabourDeleteAcid,
            layout: "/admin",
          },
          {
            path: "/labourAllViewAcid",
            name: "View All Acid",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "VAA",
            rtlMini: "شع",
            component: LabourAllViewAcid,
            layout: "/admin",
          },
          {
            path: "/labourClientViewAcid",
            name: "View Client Acid",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "VCA",
            rtlMini: "شع",
            component: LabourClientViewAcid,
            layout: "/admin",
          },
          {
            collapse: true,
            name: "Receive To Party",
            rtlName: "صفحات",
            icon: PersonIcon,
            state: "acidRecievedCollapse",
            views: [
              {
                path: "/labourReceiveAddAicd",
                name: "Add Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "AR",
                rtlMini: "شع",
                component: LabourReceiveAddAcid,
                layout: "/admin",
              },
              {
                path: "/labourReceiveEditAcid",
                name: "Edit Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "ER",
                rtlMini: "شع",
                component: LabourReceiveEditAcid,
                layout: "/admin",
              },
              {
                path: "/labourReceiveDeleteAcid",
                name: "Delete Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "DR",
                rtlMini: "شع",
                component: LabourReceiveDeleteAcid,
                layout: "/admin",
              },
              {
                path: "/labourReceiveAllViewAcid",
                name: "View All Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "VAR",
                rtlMini: "شع",
                component: LabourReceiveAllViewAcid,
                layout: "/admin",
              },
              {
                path: "/labourReceiveClientViewAcid",
                name: "View Client Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "VCR",
                rtlMini: "شع",
                component: LabourReceiveClientViewAcid,
                layout: "/admin",
              },
            ],
          },
        ],
      },
      {
        collapse: true,
        name: "Dell Issue To Party",
        rtlName: "صفحات",
        icon: PersonIcon,
        state: "dellIssueCollapse",
        views: [
          {
            path: "/labourAddDell",
            name: "Add Dell",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "AD",
            rtlMini: "شع",
            component: LabourAddDell,
            layout: "/admin",
          },
          {
            path: "/labourEditDell",
            name: "Edit Dell",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "ED",
            rtlMini: "شع",
            component: LabourEditDell,
            layout: "/admin",
          },
          {
            path: "/labourDeleteDell",
            name: "Delete Dell",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "DD",
            rtlMini: "شع",
            component: LabourDeleteDell,
            layout: "/admin",
          },
          {
            path: "/labourAllViewDell",
            name: "View All Dell",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "VAD",
            rtlMini: "شع",
            component: LabourAllViewDell,
            layout: "/admin",
          },
          {
            path: "/labourClientViewDell",
            name: "View Client Dell",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "VCD",
            rtlMini: "شع",
            component: LabourClientViewDell,
            layout: "/admin",
          },
          {
            collapse: true,
            name: "Receive To Party",
            rtlName: "صفحات",
            icon: PersonIcon,
            state: "dellRecievedCollapse",
            views: [
              {
                path: "/labourReceiveAddDell",
                name: "Add Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "AR",
                rtlMini: "شع",
                component: LabourReceiveAddDell,
                layout: "/admin",
              },
              {
                path: "/labourReceiveEditDell",
                name: "Edit Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "ER",
                rtlMini: "شع",
                component: LabourReceiveEditDell,
                layout: "/admin",
              },
              {
                path: "/labourReceiveDeleteDell",
                name: "Delete Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "DR",
                rtlMini: "شع",
                component: LabourReceiveDeleteDell,
                layout: "/admin",
              },
              {
                path: "/labourReceiveAllViewDell",
                name: "View All Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "VAR",
                rtlMini: "شع",
                component: LabourReceiveAllViewDell,
                layout: "/admin",
              },
              {
                path: "/labourReceiveClientViewDell",
                name: "View Client Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "VCR",
                rtlMini: "شع",
                component: LabourReceiveClientViewDell,
                layout: "/admin",
              },
            ],
          },
        ],
      },
      {
        collapse: true,
        name: "Cutting Issue To Party",
        rtlName: "صفحات",
        icon: PersonIcon,
        state: "cuttindIssueCollapse",
        views: [
          {
            path: "/labourAddCutting",
            name: "Add Cutting",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "AC",
            rtlMini: "شع",
            component: LabourAddCutting,
            layout: "/admin",
          },
          {
            path: "/labourEditCutting",
            name: "Edit Cutting",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "EC",
            rtlMini: "شع",
            component: LabourEditCutting,
            layout: "/admin",
          },
          {
            path: "/labourDeleteCutting",
            name: "Delete Cutting",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "DC",
            rtlMini: "شع",
            component: LabourDeleteCutting,
            layout: "/admin",
          },
          {
            path: "/labourAllViewCutting",
            name: "View All Cutting",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "VAC",
            rtlMini: "شع",
            component: LabourAllViewCutting,
            layout: "/admin",
          },
          {
            path: "/labourClientViewCutting",
            name: "View Client Cutting",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "VCC",
            rtlMini: "شع",
            component: LabourClientViewCutting,
            layout: "/admin",
          },
          {
            collapse: true,
            name: "Receive To Party",
            rtlName: "صفحات",
            icon: PersonIcon,
            state: "cuttingRecievedCollapse",
            views: [
              {
                path: "/labourReceiveAddCutting",
                name: "Add Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "AR",
                rtlMini: "شع",
                component: LabourReceiveAddCutting,
                layout: "/admin",
              },
              {
                path: "/labourReceiveEditCutting",
                name: "Edit Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "ER",
                rtlMini: "شع",
                component: LabourReceiveEditCutting,
                layout: "/admin",
              },
              {
                path: "/labourReceiveDeleteCutting",
                name: "Delete Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "DR",
                rtlMini: "شع",
                component: LabourReceiveDeleteCutting,
                layout: "/admin",
              },
              {
                path: "/labourReceiveAllViewCutting",
                name: "View All Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "VAR",
                rtlMini: "شع",
                component: LabourReceiveAllViewCutting,
                layout: "/admin",
              },
              {
                path: "/labourReceiveClientViewCutting",
                name: "View Client Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "VCR",
                rtlMini: "شع",
                component: LabourReceiveClientViewCutting,
                layout: "/admin",
              },
            ],
          },
        ],
      },
      {
        collapse: true,
        name: "Plating Issue To Party",
        rtlName: "صفحات",
        icon: PersonIcon,
        state: "platingIssueCollapse",
        views: [
          {
            path: "/labourAddPlating",
            name: "Add Plating",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "AP",
            rtlMini: "شع",
            component: LabourAddPlating,
            layout: "/admin",
          },
          {
            path: "/labourEditPlating",
            name: "Edit Plating",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "EP",
            rtlMini: "شع",
            component: LabourEditPlating,
            layout: "/admin",
          },
          {
            path: "/labourDeletePlating",
            name: "Delete Plating",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "DP",
            rtlMini: "شع",
            component: LabourDeletePlating,
            layout: "/admin",
          },
          {
            path: "/labourAllViewPlating",
            name: "View All Plating",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "VAP",
            rtlMini: "شع",
            component: LabourAllViewPlating,
            layout: "/admin",
          },
          {
            path: "/labourClientViewPlating",
            name: "View Client Plating",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "VCP",
            rtlMini: "شع",
            component: LabourClientViewPlating,
            layout: "/admin",
          },
          {
            collapse: true,
            name: "Receive To Party",
            rtlName: "صفحات",
            icon: PersonIcon,
            state: "platingRecievedCollapse",
            views: [
              {
                path: "/labourReceiveAddPlating",
                name: "Add Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "AR",
                rtlMini: "شع",
                component: LabourReceiveAddPlating,
                layout: "/admin",
              },
              {
                path: "/labourReceiveEditPlating",
                name: "Edit Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "ER",
                rtlMini: "شع",
                component: LabourReceiveEditPlating,
                layout: "/admin",
              },
              {
                path: "/labourReceiveDeletePlating",
                name: "Delete Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "DR",
                rtlMini: "شع",
                component: LabourReceiveDeletePlating,
                layout: "/admin",
              },
              {
                path: "/labourReceiveAllViewPlating",
                name: "View All Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "VAR",
                rtlMini: "شع",
                component: LabourReceiveAllViewPlating,
                layout: "/admin",
              },
              {
                path: "/labourReceiveClientViewPlating",
                name: "View Client Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "VCR",
                rtlMini: "شع",
                component: LabourReceiveClientViewPlating,
                layout: "/admin",
              },
            ],
          },
        ],
      },
      {
        collapse: true,
        name: "Packing Issue To Party",
        rtlName: "صفحات",
        icon: PersonIcon,
        state: "packingIssueCollapse",
        views: [
          {
            path: "/labourAddPacking",
            name: "Add Packing",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "AP",
            rtlMini: "شع",
            component: LabourAddPacking,
            layout: "/admin",
          },
          {
            path: "/labourEditPacking",
            name: "Edit Packing",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "EP",
            rtlMini: "شع",
            component: LabourEditPacking,
            layout: "/admin",
          },
          {
            path: "/labourDeletePacking",
            name: "Delete Packing",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "DP",
            rtlMini: "شع",
            component: LabourDeletePacking,
            layout: "/admin",
          },
          {
            path: "/labourAllViewPacking",
            name: "View All Packing",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "VAP",
            rtlMini: "شع",
            component: LabourAllViewPacking,
            layout: "/admin",
          },
          {
            path: "/labourClientViewPacking",
            name: "View Client Packing",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "VCP",
            rtlMini: "شع",
            component: LabourClientViewPacking,
            layout: "/admin",
          },
          {
            collapse: true,
            name: "Receive To Party",
            rtlName: "صفحات",
            icon: PersonIcon,
            state: "packingRecievedCollapse",
            views: [
              {
                path: "/labourReceiveAddPacking",
                name: "Add Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "AR",
                rtlMini: "شع",
                component: LabourReceiveAddPacking,
                layout: "/admin",
              },
              {
                path: "/labourReceiveEditPacking",
                name: "Edit Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "ER",
                rtlMini: "شع",
                component: LabourReceiveEditPacking,
                layout: "/admin",
              },
              {
                path: "/labourReceiveDeletePacking",
                name: "Delete Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "DR",
                rtlMini: "شع",
                component: LabourReceiveDeletePacking,
                layout: "/admin",
              },
              {
                path: "/labourReceiveAllViewPacking",
                name: "View All Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "VAR",
                rtlMini: "شع",
                component: LabourReceiveAllViewPacking,
                layout: "/admin",
              },
              {
                path: "/labourReceiveClientViewPacking",
                name: "View Client Receive",
                rtlName: "ملف تعريفي للمستخدم",
                mini: "VCR",
                rtlMini: "شع",
                component: LabourReceiveClientViewPacking,
                layout: "/admin",
              },
            ],
          },
        ],
      },
      {
        path: "/labourStatus",
        name: "Labour Status",
        rtlName: "لوحة القيادة",
        icon: PersonIcon,
        component: LabourStatus,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Image",
    rtlName: "صفحات",
    icon: PersonIcon,
    state: "imageCollapse",
    views: [
      {
        path: "/addImage",
        name: "Add Fix Image",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "AI",
        rtlMini: "شع",
        component: AddImage,
        layout: "/admin",
      },
      {
        path: "/updateImage",
        name: "Update Fix Image",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UI",
        rtlMini: "شع",
        component: UpdateImage,
        layout: "/admin",
      },
      {
        path: "/addCropImage",
        name: "Add Image",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "ACI",
        rtlMini: "شع",
        component: AddCropImage,
        layout: "/admin",
      },
      {
        path: "/updateCropImage",
        name: "Update Image",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UCI",
        rtlMini: "شع",
        component: UpdateCropImage,
        layout: "/admin",
      },
      {
        path: "/viewImage",
        name: "View Image",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "VI",
        rtlMini: "شع",
        component: ViewImage,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Terms&Condition",
    rtlName: "صفحات",
    icon: PersonIcon,
    state: "terms&ConditionCollapse",
    views: [
      {
        path: "/addTerms",
        name: "Add Terms",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "AT",
        rtlMini: "شع",
        component: AddTerms,
        layout: "/admin",
      },
      {
        path: "/updateTerms",
        name: "Update Terms",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UT",
        rtlMini: "شع",
        component: UpdateTerms,
        layout: "/admin",
      },
      {
        path: "/viewTerms",
        name: "View Terms",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "VT",
        rtlMini: "شع",
        component: ViewTerms,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/enquiry",
    name: "Enquiry",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Enquiry,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Client",
    rtlName: "صفحات",
    icon: HiOutlineUserAdd,
    state: "clientCollapse",
    views: [
      {
        path: "/addClient",
        name: "Add Client",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "AC",
        rtlMini: "شع",
        component: AddClient,
        layout: "/admin",
      },
      {
        path: "/updateClient",
        name: "Update Client Profile",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UCP",
        rtlMini: "شع",
        component: UpdateClient,
        layout: "/admin",
      },
      {
        path: "/addAddress",
        name: "Add Address",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "AA",
        rtlMini: "شع",
        component: AddAddress,
        layout: "/admin",
      },
      {
        path: "/viewClientAddress",
        name: "View Address",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "VCA",
        rtlMini: "شع",
        component: ViewClientAddress,
        layout: "/admin",
      },
      // {
      //   path: "/updateShippingAddress",
      //   name: "Update Shipping Address",
      //   rtlName: "ملف تعريفي للمستخدم",
      //   mini: "UCA",
      //   rtlMini: "شع",
      //   component: UpdateShippingAddress,
      //   layout: "/admin",
      // },
      {
        path: "/userList",
        name: "User List",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UL",
        rtlMini: "شع",
        component: UserList,
        layout: "/admin",
      },
      {
        path: "/message",
        name: "Contact Message",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "CM",
        rtlMini: "شع",
        component: Message,
        layout: "/admin",
      },
      {
        path: "/viewClientDetails",
        name: "View Client Details",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "VCD",
        rtlMini: "شع",
        component: ViewClientDetails,
        layout: "/admin",
      },
      {
        path: "/lastUpdatedClient",
        name: "Last Updated Client",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "LUC",
        rtlMini: "شع",
        component: LastUpdatedClient,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Cart",
    rtlName: "صفحات",
    icon: GiShoppingCart,
    state: "cartCollapse",
    views: [
      {
        path: "/addCart",
        name: "Add Client Cart",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "AC",
        rtlMini: "شع",
        component: AddCart,
        layout: "/admin",
      },
      {
        path: "/updateCart",
        name: "Update Client Cart",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UC",
        rtlMini: "شع",
        component: UpdateCart,
        layout: "/admin",
      },
      {
        path: "/updatePendingCart",
        name: "Update Client Pending Cart",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UPC",
        rtlMini: "شع",
        component: UpdatePendingCart,
        layout: "/admin",
      },
      {
        path: "/deleteCart",
        name: "Delete Client Cart",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "DC",
        rtlMini: "شع",
        component: DeleteCart,
        layout: "/admin",
      },
      {
        path: "/viewCart",
        name: "View Client Cart",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "VC",
        rtlMini: "شع",
        component: ViewCart,
        layout: "/admin",
      },
      {
        path: "/myOrder",
        name: "My Order",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "MO",
        rtlMini: "شع",
        component: MyOrder,
        layout: "/admin",
      },
      {
        path: "/orderHistory",
        name: "Order History",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "OH",
        rtlMini: "شع",
        component: OrderHistory,
        layout: "/admin",
      },
      {
        path: "/orderList",
        name: "Order List",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "OL",
        rtlMini: "شع",
        component: OrderList,
        layout: "/admin",
      },
      {
        path: "/orderPendingAmount",
        name: "Order Pending Amount",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "OPA",
        rtlMini: "شع",
        component: OrderPendingAmount,
        layout: "/admin",
      },
      {
        path: "/ordersCancel",
        name: "Orders Cancel",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "OC",
        rtlMini: "شع",
        component: OrdersCancel,
        layout: "/admin",
      },
      {
        path: "/cartPending",
        name: "Cart Pending",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "CP",
        rtlMini: "شع",
        component: CartPending,
        layout: "/admin",
      },
      {
        path: "/viewPendingCart",
        name: "View Pending Cart",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "VPC",
        rtlMini: "شع",
        component: ViewPendingCart,
        layout: "/admin",
      },
      {
        path: "/follower",
        name: "Follower",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "F",
        rtlMini: "شع",
        component: Follower,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Transport",
    rtlName: "صفحات",
    icon: LocalShippingIcon,
    state: "transportCollapse",
    views: [
      {
        path: "/addTransport",
        name: "Add Transport",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "AT",
        rtlMini: "شع",
        component: AddTransport,
        layout: "/admin",
      },
      {
        path: "/addBranch",
        name: "Add Branch",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "ABT",
        rtlMini: "شع",
        component: AddBranch,
        layout: "/admin",
      },
      {
        path: "/updateBranch",
        name: "Update Branch",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UBT",
        rtlMini: "شع",
        component: UpdateBranch,
        layout: "/admin",
      },
      {
        path: "/viewBranch",
        name: "view Branch",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "VB",
        rtlMini: "شع",
        component: ViewBranch,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Product",
    rtlName: "صفحات",
    icon: RiProductHuntLine,
    state: "productCollapse",
    views: [
      {
        path: "/addProduct",
        name: "Add Product",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "AP",
        rtlMini: "شع",
        component: AddProduct,
        layout: "/admin",
      },
      {
        path: "/updateProduct",
        name: "Update Product",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: UpdateProduct,
        layout: "/admin",
      },
      // {
      //   path: "/onlineProductUpdate",
      //   name: "Online Product Update",
      //   rtlName: "ملف تعريفي للمستخدم",
      //   mini: "UP",
      //   rtlMini: "شع",
      //   component: OnlineProductUpdate,
      //   layout: "/admin",
      // },
      {
        path: "/deleteProduct",
        name: "Delete Product",
        rtlName: "",
        mini: "DP",
        rtlMini: "",
        component: DeleteProduct,
        layout: "/admin",
      },
      {
        path: "/viewProduct",
        name: "View Product",
        rtlName: "",
        mini: "VP",
        rtlMini: "",
        component: ViewProduct,
        layout: "/admin",
      },
      {
        path: "/productContent",
        name: "Product Content",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: ProductContent,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Stock Product",
    rtlName: "صفحات",
    icon: RiProductHuntLine,
    state: "stockProductCollapse",
    views: [
      {
        path: "/addStockProduct",
        name: "Add Stock Product",
        rtlName: "",
        mini: "ASP",
        rtlMini: "",
        component: AddStockProduct,
        layout: "/admin",
      },
      {
        path: "/updateStockProduct",
        name: "Update Stock Product",
        rtlName: "",
        mini: "USP",
        rtlMini: "",
        component: UpdateStockProduct,
        layout: "/admin",
      },
      {
        path: "/deleteStockProduct",
        name: "Delete Stock Product",
        rtlName: "",
        mini: "DSP",
        rtlMini: "",
        component: DeleteStockProduct,
        layout: "/admin",
      },
      {
        path: "/viewStockProduct",
        name: "View Stock Product",
        rtlName: "",
        mini: "VSP",
        rtlMini: "",
        component: ViewStockProduct,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Category",
    rtlName: "صفحات",
    icon: CategoryIcon,
    state: "categoryCollapse",
    views: [
      {
        collapse: true,
        name: "Product Category",
        mini: "C",
        state: "editCategoryCollapse",
        views: [
          {
            path: "/addCategory",
            name: "Add Product Category",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "AC",
            rtlMini: "شع",
            component: AddCategory,
            layout: "/admin",
          },
          {
            path: "/updateCategory",
            name: "Update Product Category",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "UC",
            rtlMini: "شع",
            component: UpdateCategory,
            layout: "/admin",
          },
          {
            path: "/deleteCategory",
            name: "Delete Product Category",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "DC",
            rtlMini: "شع",
            component: DeleteCategory,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Product Size-MM",
        mini: "BS",
        state: "editCategorySizeCollapse",
        views: [
          {
            path: "/addCatSize",
            name: "Add Product Size-MM",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "ABS",
            rtlMini: "شع",
            component: AddCatSize,
            layout: "/admin",
          },
          {
            path: "/updateCatSize",
            name: "Update Product Size-MM",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "UBS",
            rtlMini: "شع",
            component: UpdateCatSize,
            layout: "/admin",
          },
          {
            path: "/deleteCatSize",
            name: "Delete Product Size-MM",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "DBS",
            rtlMini: "شع",
            component: DeleteCatSize,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Labour & Rate",
        mini: "LR",
        state: "editLabour&RateCollapse",
        views: [
          {
            path: "/addLabourRate",
            name: "Add Labour & Rate",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "ALR",
            rtlMini: "شع",
            component: AddLabourRate,
            layout: "/admin",
          },
          {
            path: "/updateLabRateSingle",
            name: "Update Labour & Rate",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "ULR",
            rtlMini: "شع",
            component: UpdateLabRateSingle,
            layout: "/admin",
          },
          // {
          //   path: "/updateLabourRate",
          //   name: "Update All Labour & Rate",
          //   rtlName: "ملف تعريفي للمستخدم",
          //   mini: "ULR",
          //   rtlMini: "شع",
          //   component: UpdateLabourRate,
          //   layout: "/admin",
          // },
          {
            path: "/deleteLabourRate",
            name: "Delete Labour & Rate",
            rtlName: "ملف تعريفي للمستخدم",
            mini: "DLR",
            rtlMini: "شع",
            component: DeleteLabourRate,
            layout: "/admin",
          },
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "Packing Material",
    rtlName: "packing",
    icon: GiBoxUnpacking,
    state: "packingCollapse",
    views: [
      {
        path: "/addPacking",
        name: "Add Packing Material",
        rtlName: "Add Packing",
        mini: "APD",
        rtlMini: "AP",
        component: AddPacking,
        layout: "/admin",
      },
      {
        path: "/updatePacking",
        name: "Update Packing Material",
        rtlName: "Update Packing",
        mini: "UPD",
        rtlMini: "UP",
        component: UpdatePacking,
        layout: "/admin",
      },
      {
        path: "/deletePacking",
        name: "Delete Packing Material",
        rtlName: "Delete Packing",
        mini: "DPD",
        rtlMini: "DP",
        component: DeletePacking,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    name: "Unit",
    rtlName: "unit",
    icon: AcUnitIcon,
    state: "unitCollapse",
    views: [
      {
        path: "/addUnit",
        name: "Add Unit",
        rtlName: "Add Unit",
        mini: "AU",
        rtlMini: "AU",
        component: AddUnit,
        layout: "/admin",
      },
      {
        path: "/updateUnit",
        name: "Update Unit",
        rtlName: "Update Unit",
        mini: "UU",
        rtlMini: "UU",
        component: UpdateUnit,
        layout: "/admin",
      },
      {
        path: "/deleteUnit",
        name: "Delete Unit",
        rtlName: "Delete Unit",
        mini: "DU",
        rtlMini: "DU",
        component: DeleteUnit,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    name: "Raw Metal",
    rtlName: "metal",
    icon: GiWireCoil,
    state: "metalCollapse",
    views: [
      {
        path: "/addMetal",
        name: "Add Raw Metal",
        rtlName: "Add Metal",
        mini: "ARM",
        rtlMini: "AM",
        component: AddMetal,
        layout: "/admin",
      },
      {
        path: "/updateMetal",
        name: "Update Raw Metal",
        rtlName: "Update Metal",
        mini: "URM",
        rtlMini: "UM",
        component: UpdateMetal,
        layout: "/admin",
      },
      {
        path: "/deleteMetal",
        name: "Delete Raw Metal",
        rtlName: "Delete Metal",
        mini: "DRM",
        rtlMini: "DM",
        component: DeleteMetal,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    name: "Box",
    rtlName: "box",
    icon: FaBox,
    state: "boxCollapse",
    views: [
      {
        path: "/addBox",
        name: "Add Box",
        rtlName: "Add Box",
        mini: "AB",
        rtlMini: "AB",
        component: AddBox,
        layout: "/admin",
      },
      {
        path: "/updateBox",
        name: "Update Box",
        rtlName: "Update Box",
        mini: "UB",
        rtlMini: "UB",
        component: UpdateBox,
        layout: "/admin",
      },
      {
        path: "/deleteBox",
        name: "Delete Box",
        rtlName: "Delete Box",
        mini: "DB",
        rtlMini: "DB",
        component: DeleteBox,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    name: "Piece/Sets",
    rtlName: "Piece/Sets",
    icon: FaRing,
    state: "SetCollapse",
    views: [
      {
        path: "/addPieceSets",
        name: "Add Piece/Sets",
        rtlName: "",
        mini: "APS",
        rtlMini: "",
        component: AddSets,
        layout: "/admin",
      },
      {
        path: "/updatePieceSets",
        name: "Update Piece/Sets",
        rtlName: "",
        mini: "UPS",
        rtlMini: "",
        component: UpdateSet,
        layout: "/admin",
      },
      {
        path: "/deletePieceSets",
        name: "Delete Piece/Sets",
        rtlName: "",
        mini: "DPS",
        rtlMini: "",
        component: DeleteSet,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: Image,
    state: "pageCollapse",
    views: [
      {
        path: "/labour-page",
        name: "Labour Page",
        rtlName: "عالتسعير",
        mini: "LP",
        rtlMini: "ع",
        component: LabourPage,
        layout: "/auth",
      },
      {
        path: "/add-img",
        name: "Images Add",
        rtlName: "عالتسعير",
        mini: "LP",
        rtlMini: "ع",
        component: ImagesAdd,
        layout: "/auth",
      },
      {
        path: "/update-img",
        name: "Images Update",
        rtlName: "عالتسعير",
        mini: "LP",
        rtlMini: "ع",
        component: ImagesUpdate,
        layout: "/auth",
      },
      {
        path: "/add-img-crop",
        name: "Images Add Crop",
        rtlName: "عالتسعير",
        mini: "LP",
        rtlMini: "ع",
        component: ImagesAddCrop,
        layout: "/auth",
      },
      {
        path: "/update-img-crop",
        name: "Images Update Crop",
        rtlName: "عالتسعير",
        mini: "LP",
        rtlMini: "ع",
        component: ImagesUpdateCrop,
        layout: "/auth",
      },
      {
        path: "/pricing-page",
        name: "Pricing Page",
        rtlName: "عالتسعير",
        mini: "PP",
        rtlMini: "ع",
        component: PricingPage,
        layout: "/auth",
      },
      {
        path: "/login-page",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth",
      },
      {
        path: "/register-page",
        name: "Register Page",
        rtlName: "تسجيل",
        mini: "R",
        rtlMini: "صع",
        component: RegisterPage,
        layout: "/auth",
      },
      {
        path: "/error-page",
        name: "Error Page",
        rtlName: "صفحة الخطأ",
        mini: "E",
        rtlMini: "البريد",
        component: ErrorPage,
        layout: "/auth",
      },
    ],
  },
];
export default dashRoutes;
